import ShuttleIMG from "./../../assets/image/news/Shuttle.png";
import { motion } from "framer-motion";

const newsAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: (custom) => ({
    opacity: 1,
    transition: { delay: custom * 0.9 },
  }),
};

const News = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.9, once: true }}
      className="news"
      variants={newsAnimation}
    >
      <img className="news__img" src={ShuttleIMG} alt="" />
      <div className="news__info">
        <div className="news__title">News</div>
        <div className="news__date">26 января 2024</div>
        <div className="news__description">
          Лаборатория 9 класса в настоящее время находится на завершающем этапе
          финального тестирования. Вскоре ожидается выпуск готового продукта.
        </div>
      </div>
    </motion.section>
  );
};

export default News;
