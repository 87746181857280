export const TEAMSofUnity = [
  {
    id: 1,
    name: "Рахымберді Нұрдаулет",
    linkURL: "https://www.linkedin.com/in/rnurdaulet",
    imageURL: require("./../assets/image/teams/NN.png"),
    profileTitle: "LinkedIn profile",
    jobTitle: "Unity Team Lead",
  },
  {
    id: 2,
    name: "Турман Мади",
    linkURL: "",
    imageURL: require("./../assets/image/teams/MM.png"),
    profileTitle: "",
    jobTitle: "Unity Developer",
  },
  {
    id: 3,
    name: "Куанов Ақарыс",
    linkURL: "",
    imageURL: require("./../assets/image/teams/KA.png"),
    profileTitle: "",
    jobTitle: "Unity Developer",
  },
  {
    id: 4,
    name: "Кенес Асылбек",
    linkURL: "",
    imageURL: require("./../assets/image/teams/KAs.png"),
    profileTitle: "",
    jobTitle: "Unity Developer",
  },
];

export const TEAMSofProjects = [
  {
    id: 1,
    name: "Турашев Марат",
    linkURL: "",
    imageURL: require("./../assets/image/teams/TM.png"),
    profileTitle: "",
    jobTitle: "Project Manager",
  },
  {
    id: 2,
    name: "Жапаров Адиль",
    linkURL: "https://www.artstation.com/adilzhaparov",
    imageURL: require("./../assets/image/teams/JA.png"),
    profileTitle: "Artstation profile",
    jobTitle: "Concept Artist",
  },
  {
    id: 3,
    name: "Жусупов Темирлан",
    linkURL: "https://www.artstation.com/temirlanzhussupov",
    imageURL: require("./../assets/image/teams/JT.png"),
    profileTitle: "Artstation profile",
    jobTitle: "3D Artist",
  },
  {
    id: 4,
    name: "Саркытбаева Лейла",
    linkURL: "",
    imageURL: require("./../assets/image/teams/LL.png"),
    profileTitle: "",
    jobTitle: "Material Artist",
  },
  {
    id: 5,
    name: "Байдавлетов Чингиз",
    linkURL:
      "https://www.linkedin.com/in/chingizbaidavletov?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    imageURL: require("./../assets/image/teams/BC.png"),
    profileTitle: "LinkedIn profile",
    jobTitle: "3D Artist",
  },
];

export const TEAMSofDev = [
  {
    name: "Әлиасқарова Шолпан",
    linkURL: "",
    imageURL: require("./../assets/image/teams/AS.png"),
    profileTitle: "",
    jobTitle: "QA Engineer",
  },
  {
    name: "Сейітқазы Еділқазы",
    linkURL: "",
    imageURL: require("./../assets/image/teams/SY.png"),
    profileTitle: "",
    jobTitle: "Frontend Developer",
  },
];
// chemistry
// physics
// biology

export const CHEMSUBJECTS7 = [
  {
    id: 1,
    name: "Разделение смесей",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=ch&labnumber=1",
  },
  {
    id: 2,
    name: "Исследование реакций нейтрализации",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=ch&labnumber=3",
  },
  {
    id: 3,
    name: "Индикаторы",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=ch&labnumber=2",
  },
  {
    id: 4,
    name: "Получение меди из малахита",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=ch&labnumber=4",
  },
];

export const CHEMSUBJECTS8 = [
  {
    id: 1,
    name: "Металдардың қышқыл ерітінділерімен реакциясы",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=8&sub=ch&labnumber=1",
  },
  {
    id: 2,
    name: "Металдардың белсенділігін салыстыру",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=8&sub=ch&labnumber=2",
  },
  {
    id: 3,
    name: "Температураның химиялық реакция жылдамдығына әсері",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=8&sub=ch&labnumber=3",
  },
  {
    id: 4,
    name: "Оттекті алу және оның қасиеттерін зерттеу",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=8&sub=ch&labnumber=4",
  },
];

export const CHEMSUBJECTS9 = [
  {
    id: 1,
    name: "Қышқылдардың химиялық қасиетін зерттеу",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=9&sub=ch&labnumber=1",
  },
  {
    id: 2,
    name: "Бейорганикалық қосылыстардың құрамын сапалық талдау",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=9&sub=ch&labnumber=2",
  },
  {
    id: 3,
    name: "Катиондарды Аниондарды анықтауға сапалық реакциялар",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=9&sub=ch&labnumber=3",
  },
  {
    id: 4,
    name: "Оттек және күкірт қасиеттері",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=9&sub=ch&labnumber=4",
  },
];

export const PHYSUBJECTS7 = [
  {
    id: 1,
    name: "Определение зависимости силы упругости от удлинения пружины",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=fiz&labnumber=1",
  },
  {
    id: 2,
    name: "Определение условии равновесия рычага",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=fiz&labnumber=5",
  },
  {
    id: 3,
    name: "Определение средней скорости",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=fiz&labnumber=2",
  },
  {
    id: 4,
    name: "Исследование выталкивающей силы в газах и жидкостях",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=fiz&labnumber=6",
  },
  {
    id: 5,
    name: "Исследование зависимости скорости и времени и пути от времени при равномерном движении",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=fiz&labnumber=3",
  },
  {
    id: 6,
    name: "Исследование силы трения",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=fiz&labnumber=7",
  },
  {
    id: 7,
    name: "Определение цен деления шкалы и погрешности измерительных приборов",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=fiz&labnumber=4",
  },
  {
    id: 8,
    name: "Определение КПД наклонной плоскости",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=fiz&labnumber=8",
  },
];

export const PHYSUBJECTS8 = [
  {
    id: 1,
    name: "Қатты дененің меншікті жылу сыйымдылығын анықтау",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=8&sub=fiz&labnumber=1",
  },
  {
    id: 2,
    name: "Өткізгіштерді тізбектей жалғауды зерделеу",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=8&sub=fiz&labnumber=4",
  },
  {
    id: 3,
    name: "Электр тізбегін жинау және осы тізбектің әрбір бөлігі үшін кернеу мен ток күшін өлшеу",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=8&sub=fiz&labnumber=2",
  },
  {
    id: 4,
    name: "Өткізгіштерді параллель жалғауды зерделеу",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=8&sub=fiz&labnumber=5",
  },
  {
    id: 5,
    name: "Электр тізбегінің бөлігі үшін Ом заңын зерделеу",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=8&sub=fiz&labnumber=3",
  },
  {
    id: 6,
    name: "Жинағыш линзаның фокустық қашықтығын анықтау",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=8&sub=fiz&labnumber=6",
  },
];

export const PHYSUBJECTS9 = [
  {
    id: 1,
    name: "Дененің шеңбер бойымен қозғалысын зерттеу",
    link: "№1 Дененің шеңбер бойымен қозғалысын зерттеу",
  },
  {
    id: 2,
    name: "Ньютонның екінші заңын зерттеу",
    link: "№2 Ньютонның екінші заңын зерттеу",
  },
  {
    id: 3,
    name: "Денелердің серпімді соқтығысуы кезіндегі импульстің сақталу заңын зерделеу",
    link: "№3 Денелердің серпімді соқтығысуы кезіндегі импульстің сақталу заңын зерделеу",
  },
  {
    id: 4,
    name: "Ауадағы дыбыс жылдамдығын анықтау",
    link: "№4 Ауадағы дыбыс жылдамдығын анықтау",
  },
];

export const BIOSUBJECTS7 = [
  {
    id: 1,
    name: "Изучение продуктов выделения у растений",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=bio&labnumber=1",
  },
  {
    id: 2,
    name: "Тестирование продуктов питания на наличие питательных веществ (жиры)",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=bio&labnumber=5",
  },
  {
    id: 3,
    name: "Изучение процессов осмоса в растительных клетках",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=bio&labnumber=2",
  },
  {
    id: 4,
    name: "Тестирование продуктов питания на наличие питательных веществ(углеводы)",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=bio&labnumber=6",
  },
  {
    id: 5,
    name: "Сравнение строения животной и растительной клетки",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=bio&labnumber=3",
  },
  {
    id: 6,
    name: "Исследование действия антибиотиков на рост бактерий",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=bio&labnumber=7",
  },
  {
    id: 7,
    name: "Тестирование продуктов питания на наличие питательных веществ (белки)",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=7&sub=bio&labnumber=4",
  },
];

export const BIOSUBJECTS8 = [
  {
    id: 1,
    name: "Классификация тканей растений и животных",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=8&sub=bio&labnumber=1",
  },
  {
    id: 2,
    name: "Исследование дыхания у растений",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=8&sub=bio&labnumber=4",
  },
  {
    id: 3,
    name: "Разделение пигментов методом бумажной хроматографии",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=8&sub=bio&labnumber=2",
  },
  {
    id: 4,
    name: "Исследование факторов, влияющих на процесс фотосинтеза",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=8&sub=bio&labnumber=5",
  },
  {
    id: 5,
    name: "Изучение строения мышечных тканей",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=8&sub=bio&labnumber=3",
  },
];

export const BIOSUBJECTS9 = [
  {
    id: 1,
    name: "Исследование влияния температуры на активность ферментов",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=9&sub=bio&labnumber=1",
  },
  {
    id: 2,
    name: "Влияние рН на активность ферментов. Эмульгирование жиров.",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=9&sub=bio&labnumber=2",
  },
  {
    id: 3,
    name: "Изучение форменных элементов крови (лягушки и человека)",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=9&sub=bio&labnumber=3",
  },
  {
    id: 4,
    name: "Исследование химического состава костей",
    link: "https://app.lab.nis.edu.kz/callback/?tokenid=none&cls=9&sub=bio&labnumber=4",
  },
];

// export const SHIMSUBJECTS9 = [
//   {
//     id: 1,
//     name: "",
//   },{
//     id: 2,
//     name: "",
//   },
//   {
//     id: 3,
//     name: "",
//   },
//   {
//     id: 4,
//     name: "",
//   },
// ]
export const SOCIALS = [
  {
    icon: "telegram",
    link: "t.me/Yedilkazy",
  },
  {
    icon: "instagram",
    link: "https://www.instagram.com/yedilkazy?igsh=MTZtbTg0ajdnMDY1ZQ%3D%3D&utm_source=qr",
  },
  {
    icon: "facebook",
    link: "https://www.facebook.com/yedilkazy.seiitkazy?mibextid=LQQJ4d",
  },
];

export const MENU = [
  {
    icon: "telegram",
    link: "t.me/Yedilkazy",
  },
  {
    icon: "instagram",
    link: "https://www.instagram.com/yedilkazy?igsh=MTZtbTg0ajdnMDY1ZQ%3D%3D&utm_source=qr",
  },
  {
    icon: "facebook",
    link: "https://www.facebook.com/yedilkazy.seiitkazy?mibextid=LQQJ4d",
  },
];
