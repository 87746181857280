import { Link } from "react-router-dom";
import {
  TEAMSofUnity,
  TEAMSofProjects,
  TEAMSofDev,
} from "./../../utils/constants.js";
import { motion } from "framer-motion";

const blockAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: (custom) => ({
    opacity: 1,
    transition: { delay: custom * 0.9 },
  }),
};
const Teams = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.9, once: true }}
    >
      <motion.div className="teams" variants={blockAnimation}>
        <div className="teams__title">Команда разработчиков:</div>
        <div className="teams__wrapper">
          <div className="teams__block">
            {TEAMSofUnity.map(
              ({ id, name, linkURL, profileTitle, imageURL, jobTitle }) => (
                <div
                  variants={blockAnimation}
                  className="TeamsItem"
                  custom={1}
                  key={id}
                >
                  <img className="TeamsItem__image" src={imageURL} alt="" />
                  <div className="TeamsItem__jobTitle">{jobTitle}</div>
                  <div className="TeamsItem__name">{name}</div>
                  <Link
                    to={linkURL}
                    target="_blank"
                    className="TeamsItem__profileURL"
                  >
                    {profileTitle}
                  </Link>
                </div>
              )
            )}
          </div>
          <div className="teams__block marginLeft">
            {TEAMSofProjects.map(
              ({ id, name, linkURL, profileTitle, imageURL, jobTitle }) => (
                <div className="TeamsItem" key={id}>
                  <img className="TeamsItem__image" src={imageURL} alt="" />
                  <div className="TeamsItem__jobTitle">{jobTitle}</div>
                  <div className="TeamsItem__name">{name}</div>
                  <Link
                    to={linkURL}
                    target="_blank"
                    className="TeamsItem__profileURL"
                  >
                    {profileTitle}
                  </Link>
                </div>
              )
            )}
          </div>
          <div className="teams__block marginLeft">
            {TEAMSofDev.map(
              ({ id, name, linkURL, profileTitle, imageURL, jobTitle }) => (
                <div
                  variants={blockAnimation}
                  className="TeamsItem"
                  custom={3}
                  key={id}
                >
                  <img className="TeamsItem__image" src={imageURL} alt="" />
                  <div className="TeamsItem__jobTitle">{jobTitle}</div>
                  <div className="TeamsItem__name">{name}</div>
                  <Link
                    to={linkURL}
                    target="_blank"
                    className="TeamsItem__profileURL"
                  >
                    {profileTitle}
                  </Link>
                </div>
              )
            )}
          </div>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default Teams;
